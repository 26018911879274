@import '../variables.scss';


/* -- a. Navbar  --------------------------------------------- */
.navbar {
  //@include box-shadow($light-shadow);

}

.navbar-nav {
  margin-left: auto !important;
}

.navbar,
.navbar:hover,
.account-dropdown,
.navbar-btn-container {
  height: $navbar-height;
}

.navbar,
.navbar:hover {
  background-color: $background-color;
  color: $content-color-text;
}

// .nav-link:focus,
// .nav-link:hover {
//   color:  !important;
// }


.navbar-btn-container,
.account-dropdown {
  align-items: center;
  display: inline-flex;
}

.account-dropdown {
  background-color: $secondary-color;
  border: 0;
  border-radius: 0 !important;
  color: $secondary-color-text;

  &:hover,
  &:focus {
    background-color: $secondary-color !important;
    color: $secondary-color-text !important;
  }
}

.account-dropdown,
.account-dropdown-name {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar a,
.navbar a:hover {
  color: $secondary-color;
  text-decoration: none;
}

.navbar .navbar-nav .nav-item {

  span,
  strong {
    line-height: 42px;
    margin-right: 16px;
    vertical-align: middle;
  }

  span {
    margin-right: 16px;
  }

  strong {
    margin-right: 8px;
  }
}

.nav-item.dropdown>a.dropdown-toggle::after {
  border: 0;
  height: 0;
  width: 0;
}

.navbar {
  padding: 0;

  &-brand {
    img {
      height: 58px;
      padding-right: 1rem;
    }
  }

  &-btn-container {
    padding: 0.875rem 1.5rem;

    a {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .dropdown-menu {
    margin-right: 4px;
    min-width: 250px;
  }

  .dropdown-header {
    font-size: inherit;
  }

  .dropdown-item.user-role {
    label {
      padding-left: 0.5rem;
    }
  }

  .dropdown-item {
    color: rgb(0, 0, 0);
    line-height: 24px;

    &:hover {
      background-color: $primary-color;
      color: $primary-color-text;
    }

    >i {
      font-size: 24px;
      height: 24px;
      margin-right: 20px;
      text-align: center;
      vertical-align: middle;
      width: 24px;
    }

    >span {
      vertical-align: middle;
    }
  }

  .nav-burger {
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .nav-burger[data-toggle='collapse'],
  .nav-burger[data-toggle='collapse']:hover {
    background-color: $secondary-color-text;
    border: 0;
    margin-right: 8px;
  }
}


/* -- a. Nav Drawer  ------------------------------------------ */

// These are used for the DrawerDropdown & DrawerLink Component
.ldc-dropdown .dla-link {

  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  // margin-left: 16px !important;
  border-bottom: none !important;

  background-color: $content-background-color !important;
  color: $content-color-text !important;

  // max-width: calc(230px - 16px) !important;

  a {
    // font-weight: 500;
    color: $content-color-text !important;
  }
}