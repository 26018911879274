@import '../../../../assets/styles/variables.scss';

.container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;

    .inputContainer {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: flex-start;
        input {
            
            min-width: 150px;
            border-color: $secondary-color-light;
            border-width: 0.1rem;
            @include border-radius($content-border-radius);
        }
    }

    .submitButton {
        max-width: 100px;
    }
}