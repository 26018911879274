@import "510.scss";
@import "./bulma/sass/utilities/mixins";
html {
    background: $light-grey;
}
p {
    font-size: 22px;
  }

.router-content {
    min-height: 550px;
    margin-bottom: 30px;
}

.components-image {
    width: 100%;
    margin: 30px auto 0 auto;
    display: block;
}

.components-image-caption {
    margin-bottom: 30px;
    display: block;
    text-align: center;
    font-style: italic;
}
.center {
    margin-left: auto;
    margin-right: auto;
    table-layout:fixed;
    
  }
.term-column-header {
    width: 20%;
}

.bitrapy_level { //trouble!!!
    margin-bottom: 1rem !important;
    margin-left: 1rem !important;
    &.is-mobile {
        .level-item {
          &:not(:last-child) {
            margin-bottom: 0;
            @include ltr-property("margin", 1rem);
          }
          &:not(.is-narrow) {
            flex-grow: 1;
          }
        }
      }
      @include tablet {
        display: flex;
        & > .level-item {
          &:not(.is-narrow) {
            flex-grow: 1;
          }
        }
      }
    
}

.field {
    margin-bottom: 1rem;
    margin-right:1rem;
    display: flex;
}

// .field-label {
//     text-align: left;
//     margin-right: 0;
//     margin-left: 2rem;
//     font-weight: 300;
// }

.field-body {
    flex-grow: 0;
    margin-right: 2rem;
}

.round-interaction {
    height: 84px;
    width: 84px;
    background: $white;
    border-radius: 290486px;
    padding-top: 16px;
    text-align: center;

    .heading {
        font-size: 10px;
    }
}


.download-report-interaction {
    cursor: pointer;

    &:hover {
        &,
        & .icon {
            color: $primary;
        }
    }
}
.boxed {
    border: 3px solid black ;
}
  
  .badge-interaction {
    cursor: pointer;

    .title {
        width: 80%;
        margin: 12px auto;
        font-weight:600;
    }

    &:hover {
        .heading {
            color: $primary;
        }
    }
}

.round-score {
    height: 84px;
    width: 84px;
    line-height: 64px;
    border-radius: 290486px;
    font-size: 1.5rem;
    font-weight:600
}

.round-score-heading {
    position: absolute;
    width: 80%;
    top: 45px;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    left: 10%;
}

.legend {
    .round-score {
        height: 100px;
        width: 100px;
        line-height: 90px;
        font-size: 1rem;
    }

    .round-score-heading {
        top: 35px;
    }
}

td {
    padding: 0 15px;
  }

.margin-0 {
    margin: 0 !important;
}

.margin-1 {
    margin: 0.5rem !important;
}




nav {
    display: inline;
    float: center;
    
    }

    
.horizontal-mobile {
    width: 40%;
    display: inline-block;
}

@media screen and (min-width: 700px) {
    .navbar-item.is-hoverable:hover .navbar-dropdown {
        display: block !important;
    }
    .navbar-item.is-hoverable:focus-within .navbar-dropdown {
        display: none;
    }

    .round-score {
        height: 128px;
        width: 128px;
        line-height: 110px;
        font-size: 2rem;
    }

    .round-score-heading {
        top: 75px;
    }

    .round-interaction {
        height: 128px;
        width: 128px;
        padding-top: 32px;

        .heading {
            font-size: 20px;
        }
    }

    .components-image {
        width: 50%;
    }
}

@each $name, $pair in $colors {
    $color: nth($pair, 1);
    .has-text-#{$name} {
      color: $color !important;
    }
    .has-background-#{$name} {
      background-color: $color !important;
    }
  }

.is-hidden{
  display: none !important
}

.hero-body {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 3rem 1.5rem;
    @include tablet {
      padding:  3rem 3rem;
    }
  }
