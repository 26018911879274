@import "../../../../../../assets/styles/variables.scss";


.container,
.content, .divider {
  min-height: 40vh;
}

.container {
  position: relative;
  height: 100%;

  .content {
    position: relative;
    display: inline-flex;
    justify-content: center;
    width: 100%;

    .divider {
      height: 100%;
      background-color: $content-border-color;
      width: 2px;
      z-index: 1;
    }

  }


}