@import '../../assets/styles/variables.scss';

.container {
    display: inline-block;
    max-width: 300px;
    text-align: left;
    position: relative; //was fixed before? 
    top: 125px;

    .addOnText {
        position: relative;
        bottom: 40px;

        .addOnHeader {
            font-size: 50px;
            color: $content-color-text;
        }

        .addOnContent {
            position: relative;
            top: 10px;
            color: $content-color-text;
        }
    }

    .AddOnsContent {
        position: relative;
        left: 280px;
        bottom: 170px;
        display: inline-flex;

        .AddOnsContentLeft {
            .MTI {
                position: relative;
                height: 320px;
                width: 350px;
                border-radius: 8px;
                border-color: transparent;
                background-color: $nuenergy-blue;
                color: $background-color;
                font-size: 40px;
                text-align: left;
                padding-bottom: 210px;
                padding-left: 15px;
            }

            .IEEE {
                position: relative;
                top: 20px;
                height: 175px;
                width: 350px;
                border-radius: 8px;
                border-color: transparent;
                background-color: $nuenergy-orange;
                color: $background-color;
                font-size: 30px;
                text-align: left;
                padding-bottom: 150px;
                padding-left: 15px;
            }

        }

        .MTI div {
            display: none;
        }

        .MTI a {
            color: Black;
        }

        .MTI:hover div {
            display: block;
            font-size: 12px;
        }

        .IEEE div {
            display: none;
        }

        .IEEE:hover div {
            display: block;
            font-size: 12px;
        }

        .IEEE a {
            color: black
        }

        .AddOnsContentRight {
            position: relative;
            left: 20px;

            .EU {
                position: relative;
                height: 225px;
                width: 350px;
                border-radius: 8px;
                border-color: transparent;
                background-color: $nuenergy-orange;
                color: $background-color;
                font-size: 30px;
                text-align: left;
                padding-bottom: 200px;
                padding-left: 15px;
            }

            .CIOSC {
                position: relative;
                top: 20px;
                height: 225px;
                width: 350px;
                border-radius: 8px;
                border-color: transparent;
                background-color: $nuenergy-orange;
                color: $background-color;
                font-size: 30px;
                text-align: left;
                padding-bottom: 200px;
                padding-left: 15px;
            }
        }

        .EU div {
            display: none;
        }

        .EU:hover div {
            display: block;
            font-size: 12px;
        }

        .EU:hover {
            height: 450px;
            width: 350px;
            border-radius: 8px;
            border-color: transparent;
            background-color: $nuenergy-orange;
            color: $background-color;
            text-align: left;
            padding-bottom: 250px;
            padding-left: 15px;
        }

        .EU a {
            color: black
        }

        .CIOSC div {
            display: none;
        }

        .CIOSC:hover div {
            display: block;
            font-size: 12px;
        }
        .CIOSC:hover {
            height: 335px;
            width: 350px;
            border-radius: 8px;
            border-color: transparent;
            background-color: $nuenergy-orange;
            color: $background-color;
            text-align: left;
            padding-bottom: 250px;
            padding-left: 15px;
        }

        .CIOSC a {
            color: black
        }

    }

    .contactText {
        position: relative;
        top: 20px;
        left: 50px;
        max-width: 150px;

        .contactHeader {
            font-size: 30px;
            color: $content-color-text;
            width: 150px;

        }

        .contactContent {
            position: relative;
            top: 40px;
            color: $content-color-text;
            width: 150px;

        }

    }
}