@import '../../../../../assets/styles/variables.scss';


.container {
  padding: 1rem;
  // border-bottom: $content-border-color 1px solid;
  width: 100%;

}

.error {
  color: red
}

.toolsScoreCol{
  position: relative;
  left: 100px;
}

.toolsDefCol{
  position: relative;
padding-bottom: 30px;
}