
  
  .dropdown {
    position: absolute;
    cursor: pointer;
    left:200px;
  }
  
  .dropdown .dropdownToggle {
    appearance: none;
    background: #3F51B5;
    color: #fff;
    border: 0;
    padding: 12px 35px 12px 12px;
    border-radius: 3px;
    text-align: left;
    position: relative;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
  }
  
  .dropdown .dropdownToggle::after {
    content: '';
    border-width: 6px;
    border-radius: 3px;
    border-style: solid;
    border-color: transparent;
    border-top-color: inherit;
    position: absolute;
    right: 12px;
    top: calc(50% + 3px);
    transform: translateY(-50%);
  }
  
  .dropdown .dropdownToggle.active::after {
    border-color: transparent;
    border-bottom-color: inherit;
    top: calc(50% - 3px);
  }
  
  .dropdown .dropdownMenu {
    position: absolute;
    left: 50%;
    top: 80%;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 1px 4px rgba(150, 150, 150, 0.65);
    list-style: none;
    padding: 5px 0;
    margin: 0;
    max-width: 320px;
    width: calc(100% + 24px);
    border-radius: 3px;
    z-index: 10;
    visibility: hidden;
    overflow: auto;
    max-height: 200px;
  }
  
  .dropdown .dropdownMenu[aria-expanded="true"] {
    visibility: visible;
  }
  
  .dropdown .dropdownMenu > li {
    height: 35px;
    display: block;
    align-items: center;
    padding: 10px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .dropdown .dropdownMenu > li:hover,
  .dropdown .dropdownMenu > li:focus {
    outline: none;
    background: #f2f2f2;
  }
  