@import '../../../../../assets/styles/variables.scss';


.container {
  padding: 1rem;
  // border-bottom: $content-border-color 1px solid;
  width: 100%;

  span {
    cursor: pointer;
  }

}

.container,
.error {
  padding: 1rem;
}

.error {
  color: red;
}

.tooltipContainer {
  // z-index: 2000;
  // max-width: 300px;

  .title {}

  .equation {
    align-self: center;
    text-align: center;
    margin: 1rem;
    width: 100%;
  }
}
.toolsScoreCol{
  position: relative;
  left: 100px;
}

.toolsDefCol{
  position: relative;
padding-bottom: 30px;
}
