@import '../../../../assets/styles/variables.scss';


.container {
  position: relative;
  display: flex;
  max-width: 250px;
  height: 300px;
  background-color: $content-background-color;
  color: $content-color-text;
  margin-right: 2rem;
  margin-bottom: 2rem;

  @include box-shadow($light-shadow);
  @include border-radius($content-border-radius);

  .header {
    padding: 1.5rem 0.75rem;


  }

  .content {

    

  }




}