@import '../../../../../assets/styles/variables.scss';

.main {
  position: relative;
  display: flex;
  margin:  0 auto;
  align-content: center;
  justify-content: center;
  height: 100vh;

  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

}

.consent {
  background-color: $content-background-color;
  margin: 10vh auto;
  max-height: 75vh;
  max-width: 10in;
  padding: 1vw;
  overflow-y: hidden;

  .consentHeader, .consentFooter {
    height: 25%;
  }
  .consentHeader {
    padding: 16px;
  }

  .consentBody {
    position: relative;
    padding: 16px;
    border-top: 1px solid $content-border-color;
    border-bottom: 1px solid $content-border-color;

    margin:0 auto;
    height: 50%;
    overflow-y: scroll;
  }

  .consentFooter {
    margin-top: 5vh !important;

    .consentBtns { 
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
    }

  }
}


// Styles exported from Adobe HTML Export
.swAgreement {
  position: relative;
  padding: 0 8px;

  * {
    margin: 0;
    padding: 0;
    text-indent: 0;
  }
  p {
    color: black;
    // font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 13pt;
    margin: 0pt;
  }
  .a,
  a {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
  }
  li {
    display: block;
  }
  #l1 {
    padding-left: 0pt;
    counter-reset: c1 1;
  }
  #l1 > li > *:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal) ". ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
  }
  #l1 > li:first-child > *:first-child:before {
    counter-increment: c1 0;
  }
  #l2 {
    padding-left: 0pt;
    counter-reset: c2 1;
  }
  #l2 > li > *:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal) "." counter(c2, decimal) ". ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
  }
  #l2 > li:first-child > *:first-child:before {
    counter-increment: c2 0;
  }
  #l3 {
    padding-left: 0pt;
    counter-reset: d1 2;
  }
  #l3 > li > *:first-child:before {
    counter-increment: d1;
    content: counter(d1, decimal) " ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
  }
  #l3 > li:first-child > *:first-child:before {
    counter-increment: d1 0;
  }
  #l4 {
    padding-left: 0pt;
    counter-reset: d2 1;
  }
  #l4 > li > *:first-child:before {
    counter-increment: d2;
    content: counter(d1, decimal) "." counter(d2, decimal) " ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
  }
  #l4 > li:first-child > *:first-child:before {
    counter-increment: d2 0;
  }
  #l5 {
    padding-left: 0pt;
    counter-reset: e1 1;
  }
  #l5 > li > *:first-child:before {
    counter-increment: e1;
    content: "(" counter(e1, lower-latin) ") ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
  }
  #l5 > li:first-child > *:first-child:before {
    counter-increment: e1 0;
  }
  #l6 {
    padding-left: 0pt;
    counter-reset: f1 1;
  }
  #l6 > li > *:first-child:before {
    counter-increment: f1;
    content: "(" counter(f1, lower-latin) ") ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
  }
  #l6 > li:first-child > *:first-child:before {
    counter-increment: f1 0;
  }
  #l7 {
    padding-left: 0pt;
    counter-reset: g1 3;
  }
  #l7 > li > *:first-child:before {
    counter-increment: g1;
    content: counter(g1, decimal) " ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
  }
  #l7 > li:first-child > *:first-child:before {
    counter-increment: g1 0;
  }
  #l8 {
    padding-left: 0pt;
    counter-reset: g2 1;
  }
  #l8 > li > *:first-child:before {
    counter-increment: g2;
    content: counter(g1, decimal) "." counter(g2, decimal) " ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
  }
  #l8 > li:first-child > *:first-child:before {
    counter-increment: g2 0;
  }
  #l9 {
    padding-left: 0pt;
    counter-reset: h1 5;
  }
  #l9 > li > *:first-child:before {
    counter-increment: h1;
    content: counter(h1, decimal) " ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
  }
  #l9 > li:first-child > *:first-child:before {
    counter-increment: h1 0;
  }
  #l10 {
    padding-left: 0pt;
    counter-reset: h2 1;
  }
  #l10 > li > *:first-child:before {
    counter-increment: h2;
    content: counter(h1, decimal) "." counter(h2, decimal) " ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
  }
  #l10 > li:first-child > *:first-child:before {
    counter-increment: h2 0;
  }
  #l11 {
    padding-left: 0pt;
    counter-reset: i1 6;
  }
  #l11 > li > *:first-child:before {
    counter-increment: i1;
    content: counter(i1, decimal) " ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
  }
  #l11 > li:first-child > *:first-child:before {
    counter-increment: i1 0;
  }
  #l12 {
    padding-left: 0pt;
    counter-reset: i2 1;
  }
  #l12 > li > *:first-child:before {
    counter-increment: i2;
    content: counter(i1, decimal) "." counter(i2, decimal) " ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
  }
  #l12 > li:first-child > *:first-child:before {
    counter-increment: i2 0;
  }
  #l13 {
    padding-left: 0pt;
    counter-reset: j1 1;
  }
  #l13 > li > *:first-child:before {
    counter-increment: j1;
    content: "(" counter(j1, lower-latin) ") ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
  }
  #l13 > li:first-child > *:first-child:before {
    counter-increment: j1 0;
  }
  li {
    display: block;
  }
  #l14 {
    padding-left: 0pt;
    counter-reset: k1 11;
  }
  #l14 > li > *:first-child:before {
    counter-increment: k1;
    content: counter(k1, decimal) " ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
  }
  #l14 > li:first-child > *:first-child:before {
    counter-increment: k1 0;
  }
  #l15 {
    padding-left: 0pt;
    counter-reset: k2 1;
  }
  #l15 > li > *:first-child:before {
    counter-increment: k2;
    content: counter(k1, decimal) "." counter(k2, decimal) " ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
  }
  #l15 > li:first-child > *:first-child:before {
    counter-increment: k2 0;
  }
}
