@import "../../../../../../assets/styles/variables.scss";



.labelSelectContainer {
  border-top: $content-border-color 1px solid;
  padding: 16px 0;

  .labelSelectHeader {
    span {
      font-size: 1.5rem;
    }

  }

  .labelSelectHeader {

    span {
      font-size: 16px;
    }

  }

  .selectLabelContainer {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    .selectLabel,
    .selectLabel select {
      margin: 0 1rem;
    }
    
    
    .selectLabel {
      // display: flex;
      // flex-direction: column;
      font-weight: 600;

    }
  }
}