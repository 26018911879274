@import "../../../../assets/styles/variables.scss";

.pendingButton{
    background-color:transparent;
    border: transparent;
    color:$secondary-color-dark;
    padding-left: 30px;
    // &:hover{
    //     background-color:transparent;
    //     border: transparent;
    //     color:$secondary-color-dark;
    // }
    // &::after{
    //     background-color:transparent;
    //     border: transparent;
    //     color:$secondary-color-dark;
    // }
}

// .table-responsive {
.scoresTableWrapper {
    min-height: 200px;
}
