@import '../../../../../assets/styles/variables.scss';


.container {
  position: relative;
  display: flex;
  flex-direction: column;


  padding: 1.5rem 0.75rem;
  background-color: $content-background-color;

  @include border-radius($content-border-radius);
  //@include box-shadow($light-shadow);


  section {
    display: block;
    padding: 1.5rem 0;
  }



}