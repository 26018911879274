@import '../../assets/styles/variables.scss';

.downloadpdfbutton{
    @include border-radius(50%);
    //@include box-shadow($shadow-box);
  
    background-color: $primary-color;
    border: 0;
    bottom: 25px;
    color: $primary-color-text;
    cursor: pointer;
    font-size: 24px;
    height: 60px;
    padding: 15px;
    position: fixed;
    right: 20px;
    text-align: center;
    padding-top: 18px;
  
    /* Fixed/sticky position */
    width: 60px;
    z-index: 991;
}