@import "../../assets/styles/variables.scss";


.container {
  height: 20;
  width: 100%;
  background-color: $background-color;
  // border-radius: 50;
  margin: 50;

  //@include box-shadow($light-shadow);

  .filler {
    height: '100%';
    // width: `${completed}%`;
    // backgroundColor: bgcolor;
    border-radius: 'inherit';
    text-align: 'right';

  }

  .label {
    padding: 5px;
    color: $primary-color-text;
    font-weight: bold;
  }
}