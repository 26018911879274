@import '../../../../assets/styles/variables.scss';



.navbar {
  position: fixed;
  top:0;
  height: 80px;
  width: 100vw;
  z-index: 1030;
  background-color: $background-color;
  
  .container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: inherit;

    .nuEnergyBrand {
      position: relative;
      padding-left: 10px;
    }

    .middle {
      // height: calc(80px-16px);
      position: relative;
      left: 30px;
      margin: auto;
      color: $content-color-text;
    }

    .navItems {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .btnContainer {
        button {
          border: none;
          background-color: inherit;

          i {
            font-size: 1.5rem;
            color: $content-color-text;
          }
        }
      }

      .userContainer {
        position: relative;
        display: inline-flex;
        height: inherit;
        padding-left: 10px;
        padding-right: 20px;

        .userIcon {
          margin: auto;
          padding: 10px;
          padding-left: 8px;
          padding-top: 14px;

          text-align: center;
          font-size: 22px;

          height: calc(80.5px - 20px);
          width: calc(80.5px - 20px);
          // padding: 0.25rem;
          border-radius: 50% !important;
          background-color: $nuenergy-purple;
          color: #fff;

        }
      }

      .organizationLogo {
        position: relative;
        // width: 100%;
        max-height: calc(80px - 16px);
        padding-right: 30px;

        img {
          width: 100%;
          min-width: calc(80px - 16px);
          max-height: calc(80px - 16px);
          max-width: 200px;
        }
      }
    }
  }
}