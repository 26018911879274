@import '../../assets/styles/variables.scss';

.rippleButton {
  position: relative;
  display: flex;
  background: $primary-color;
  color: $content-background-color;
  // margin: 8px;
  border: none;

  padding: .375rem .75rem;

  align-self: center;
  align-items: center;
  font-weight: 400;
  line-height: 1.5;

  // font-size: 1.5rem;

  vertical-align: middle;

  overflow: hidden;
  cursor: pointer;
  text-align: center;

  justify-content: center;
  align-content: center;

  user-select: none;


  @include border-radius($content-border-radius);

  // @include box-shadow($light-shadow);

  &:disabled {
    // pointer-events: stroke;
    cursor: not-allowed;
    opacity: .65;
  }
}

.ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  background: $primary-color-light;
  display: block;
  content: "";
  border-radius: 50%;
  opacity: 1;
  transform: scale(0);
  animation: ripple-effect .5s linear;
}

.nu-btn-primary {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: $primary-color-text !important;

  .ripple {
    background: $primary-color-light !important;
  }
}

.nu-btn-secondary {
  background-color: $secondary-color !important;
  border-color: $secondary-color !important;
  color: $secondary-color-text !important;

  .ripple {
    background: $secondary-color-light !important;
  }
}

.nu-btn-purple {
  background-color: $nuenergy-purple !important;
  border-color: $nuenergy-purple !important;
  color: $secondary-color-text !important;

  .ripple {
    background: $nuenergy-purple-light !important;
  }
}

.nu-btn-white {
  background-color: $content-background-color !important;
  border-color: $content-border-color !important;
  color: $content-color-text !important;

  .ripple {
    background: $content-color-text !important;
  }
}

.nu-btn-warn {
  background-color: $content-background-color !important;
  border-color: $content-border-color !important;
  color: $content-color-text !important;

  .ripple {
    background: $content-color-text !important;
  }
}

.nu-btn-alert {
  background-color: rgb(220, 20, 20) !important;
  // border-color: $content-border-color !important;
  color: $content-border-color !important;

  .ripple {
    background: palevioletred !important;
  }
}

.nu-btn-green,
.nu-btn-third {
  background-color: $third-color;
  color: $third-color-text;

  .ripple {
    background-color: $third-color-light;
  }

}




@keyframes ripple-effect {
  to {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }


  50% {
    -webkit-transform: scale(10);
    -moz-transform: scale(10);
    transform: scale(10);
    opacity: 0.375;
  }

  100% {
    -webkit-transform: scale(25);
    -moz-transform: scale(35);
    transform: scale(35);
    opacity: 0;
  }
}

.content {
  position: relative;
  z-index: 2;
  margin: 0 auto;
  // font-size: 16px;
  // font-size: 1vw;
}