@import '../../../../assets/styles/variables.scss';


.container {

  @include border-radius($content-border-radius);

  .header {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    // height: 58px;
    padding: 8px 16px;

    background-color: $backdrop-color;

    @include border-radius($content-border-radius $content-border-radius 0 0);
  }

  table {
    background-color: $content-background-color;
    color: $content-color-text;

    @include border-radius(0 0 $content-border-radius $content-border-radius);
    
    tbody {
      td tr {
        height: 2rem !important;
        max-height: 2rem !important;
      }

      td {
        color: $content-color-text;
      }

    }

    .projectName {
      color: $primary-color;
      text-decoration: none;
      font-weight: 400;
    }

    .projectDescription {
      color: $content-color-text;

      i {
        padding: 5px;
        visibility: hidden;
      }

      &:hover {
        color: $primary-color;
        cursor: pointer;
        i {
          visibility: visible;
        }
      }
    }
  }
}

.deleteProjectBtn {
  background-color: $color-map-red;
  color: $secondary-color-text;
  width: 100px;
  border-radius: 6px;
  border-color: transparent;
}