/*
 * File: standards-container.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:36:32 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:41:07 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

@import '../../variables';


.standards-container {
  //@include box-shadow($shadow-box);
  @include border-radius(5px);

  max-width: 500px;

  .card-body {
    display: flex;
    flex-wrap: wrap;
    height: fit-content !important;
    justify-content: center;
  }
}