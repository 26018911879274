@import "../../../../assets/styles/variables.scss";



.header {
  align-items: left;
  display: flex;
  padding-top: 20px;
  width: 100%;
  font-size: 25px;
  font-weight: 200;
}

.organizationScore {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 16px;
  width: 100%;
  font-size: 30px;
  font-weight: 700;

  &__empty {
    padding: 16px;
    text-align: left;
  }
}

.organizationName {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 4px;
  width: 100%;
  font-size: 18px;
  font-weight: 300;

  &__empty {
    padding: 16px;
    text-align: left;
  }
}

.noBenchmarkScore {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  width: 100%;
  font-size: 20px;
  font-weight: 500;

  &__empty {
    padding: 16px;
    text-align: left;
  }
}

.benchmarkSpinner {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 190px;
  top: 30px;
  color: #20a8d8;
}