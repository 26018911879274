@import "../../assets/styles/variables.scss";

.container {
  position: fixed !important;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: rgba($nuenergy-blue, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 8000;

  .card {
    position: relative;
    display: flex;
    max-width: 560px;
    min-width: 300px;
    background-color: $content-background-color;

    .actionPrincipal {
      margin-bottom: 0.75rem;
      font-size: 1.5rem;
      font-weight: bold;

    }

    .cardBody {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: left;

      p {
        margin: 0 auto;
        text-align: center;
      }
    }

    .cardFooter {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      background-color: inherit;
      border: none;
    }
  }
}
