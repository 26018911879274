@import "../../assets/styles/variables.scss";


.uploader {
  position: relative;
  // // display: flex;
  // width: 100%;
  // height: 100%;

  .content {
    display: flex;
    flex-direction: column;

    .uploadBtn {
      display: flex;
      margin: 8px 0;

      .fileName {
        margin-left: 1.5rem;
      }
    }

  }

  // .content {
  //   position: relative;
  //   display: flex;
  //   flex-direction: column;

  //   padding: 1rem;
  //   // margin: 0 auto;
  //   width: 100%;
  //   height: 100%;

  //   background-color: aqua;

  //   // @include border-radius($content-border-radius);
  //   // border: 5px dashed $content-border-color;


  //   .uploadBtn {
  //     margin: 8px auto;
  //   }


  //   .uploadBtn {
  //     @include box-shadow($light-shadow) // &:hover {
  //     //   // box-shadow: 0 2px 0 0 #498C25, 0 2px 10px 0 #6ECE3B;
  //     // }
  //   }

  // }

  // .footer {
  //   display: flex;
  //   width: 100%;
  //   justify-content: center;
  //   margin: 0 auto;
  //   padding: 0.375rem 0.75rem;

  //   .submit {
  //     align-self: center;
  //   }
  // }

  .input {
    display: none;
  }


}