@import '../../assets/styles/variables.scss';


// Set the max and min width 
.label,
.link {
  // The width of the drawers is set by the animation found in 
  // features/ui/components/NavDrawer
  width: 100%;
}


/* ----- Link Group ----- */
.linkContainer,
.linkContainer .link,
.linkDropdownContainer .linkDropdownLabel {
  transition: ease-in 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.linkDropdownContainer,
.linkContainer {
  margin: 0.25rem 0;
  cursor: pointer;


}

.linkDropdownContainer .linkDropdownLabel,
.linkContainer {

  &.selected,
  &:hover,
  &:focus {
    background-color: $primary-color !important;
    font-weight: 700 !important;
    color: $secondary-color-text !important;
  }

  background-color:$secondary-color;
  padding: 0.75rem 1.5rem;
  // @include box-shadow($light-shadow);

}

.linkDropdownContainer,
.linkDropdownContainer .linkDropdownLabel,
.linkContainer {
  @include border-radius($content-border-radius);
}

.linkDropdownLabel,
.linkContainer .link {
  text-decoration: none;
  font-weight: bolder;
  font-style: normal;
  text-align: center;

  // color: $secondary-color-text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  // Truncate Links with Ellipsis
  span {
    width: 100%;
    padding-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

.linkDropdownContainer {
  position: relative;

  .linkDropdownLabel {
    color: $secondary-color-text;
    width: 100% !important;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

  }
}

/* ----- Link Button ----- */
.linkContainer {
  display: inline-flex;
  width: 100%;
  color: $secondary-color-text;

  &.selected,
  &:hover,
  &:focus {
    font-weight: 700;
  }
  .link {
    // background-color: aqua;
    height: 100%;
    width: 100%;
    font-weight: bolder;
    font-style: normal;

    text-decoration: none;


    &.disabled {
      color: rgba(0, 0, 0, 0.5);
      text-decoration: line-through;

      &:hover,
      &:focus {
        background-color: inherit;
      }
    }
  }
}