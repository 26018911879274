@import "../../../../assets/styles/variables.scss";


.container {
  position: fixed;
  display: flex;
  flex-direction: row;
  z-index: 1000;
  width: 100%;
  height: 4rem;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  margin-bottom: 16px;
  text-align: center;
  // justify-content: flex-start;
  align-items: center;
  // align-content: center;

  font-size: 1rem;
  font-weight: 700;
  // background-color: $background-color;
  background-image: linear-gradient(to bottom,
      rgba($background-color, 1),
      rgba($background-color, 0.6) 90%);

  box-shadow: 5px 0 10px rgba($background-color, 0.6);
}

.breadcrumb {
  text-align: center;
  display: inline-block;
  padding: 0.60rem;
  text-decoration: none;
  color: $secondary-color-light;
  cursor: pointer;
  text-transform: capitalize;

  i {
    padding-right: 0.70rem;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 300;
    position: relative;
    top: 6px;

  }

}

.title {
  text-align: center;
  display: inline-block;
  padding: 0.70rem;
  text-decoration: none;
  color: $secondary-color-light;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 300;


  &:hover,
  &.selected {
    color: $primary-color !important;
  }

  &.selected {
    font-weight: 700 !important;
  }
}