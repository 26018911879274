/*
 * File: tabs.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:32:34 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:40:18 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

@import '../../variables';


.tabs {
  cursor: pointer;
  display: flex;
  list-style: none;
  margin: 0;
  // overflow: hidden;
  overflow-y: hidden;
  padding: 0;
  padding-right: 16px;
  padding-left: 0px;
  width: 100%;
  width: fit-content;

  li {
    float: left;
    // justify-self: flex-start;
    margin: 0 0.5em 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  a {
    @include border-radius(5px 5px 0 0);
    // @include box-shadow($tabs-light-shadow);

    background-color: $backdrop-color;
    color: $content-color-text;
    float: left;
    padding: 0.7em 0.75em;
    position: relative;
    text-decoration: none;
    // border-left: 0.25px solid $content-border-color;

    &:hover,
    &:hover::after,
    &:focus,
    &:focus::after,
    .active {
      background: $content-background-color;
    }

    &:focus {
      outline: 0;
    }
  }

  .active a,
  .active a::after {
    background: $content-background-color;
    z-index: 3;
  }
}

.tabs-content-container {
  display: flex;
  flex-direction: column;
  // min-width: 400px;
  width: 100%;
  // min-width: calc(100vw - 650px); // got 650 by finding where the tabel ended which was 340px then added 270px which is button size plus 20px on both sides

  .tabs-content {
    // height: fit-content !important;
    @include border-radius($content-border-radius);
    //@include box-shadow($light-shadow);

    /* required */
    background-color: $content-background-color;
    display: flex;
    flex-flow: row nowrap;
    min-height: 100px;
    position: relative;
    bottom:1.5px;

    .content-display {
      box-sizing: border-box;

      /* required */
      flex: none;

      /* required */
      min-height: 100px;

      /* required */
      width: 100%;

    }
  }
}