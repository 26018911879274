@import "../app/assets/styles/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;

  .main {
    position: relative;
    // display: flex;
    // flex-direction: row;
    min-height: calc(100vh - 160px);
    // height: calc(100vh - 160px);
    width: 100%;
    margin: 0;
    margin-left: 16px;
    margin-top: 160px;
    padding-top: 0 !important;
    // padding-right: 10px !important;
    padding-left: 36px !important;
    background-color: $background-color;
    // background-color: aqua;

    // overflow: hidden;
    /* code added */

    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    // transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    .header {
      // height: 48px;
      max-height: 4rem;
      // display: inline-flex;
      // flex-direction: row;
      // justify-content: space-between;

    }

    .content {
      position: relative;
      margin-top: 5rem;
      padding-bottom: 3vh;
      overflow: auto;
    }


  }
}