/*
 * File: scorecard-results.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 6:07:19 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 20th August 2021 2:16:29 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../../variables';

.project-surveys-titles {
  font-size: 30px;
  display: flex;
  position: relative;
}

.scorecard-results {
  &__nuenergy-logo {
    border-bottom: 1px solid $content-border-color;
    max-height: 60px;
    padding: 0 8px 8px;

    img {
      height: 30px;
    }
  }

  &__title {
    border-bottom: 1px solid $content-border-color;
    display: flex;
    padding: 15px 15px 0;
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: 200;
    }
  }

  &__container {
    position: relative;
    margin-top: 25px;
    min-height: 50vh;
  }

  &__scorecard-title {
    display: flex;
    justify-content: center;
    margin: 2em auto;
    width: 80%;

    h1,
    h2 {
      font-size: 50px;
    }

    .result-scorecard-title {
      font-weight: 300;
    }

    .overall-score {
      font-weight: 700;
    }
  }

  &__requirement-scores {
    list-style-type: none;
    width: 100%;

    .requirement-score {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
      padding: 10px;
      padding-right: 5vw;
      width: 100%;

      .requirement-progress {
        &__label {
          display: flex;
          flex-direction: column;
        }

        &__bar {
          justify-self: flex-end;
          margin: auto 0;
          min-width: 250px !important;
        }
      }
    }
  }

  &__requirement_question-scores {
    margin-top: 25px;

    .requirement {
      margin: 15px;
      padding: 15px 0;

      &__header {
        font-size: 1.5em;
        font-weight: bold;
      }

      &__question-list {
        list-style-type: none;
        width: 100%;

        .requirement-question-result {
          margin: 0 auto;
          padding: 15px 0;
          width: 100%;

          &__header {
            display: inline-flex;
            font-weight: bolder;
            justify-content: space-between;
            width: 100%;

            h5 {
              hyphens: auto;
              max-width: calc(100% - 150px);
              overflow-wrap: break-word;
              word-wrap: break-word;
            }

            .header-question-score {
              // float: right;
              font-weight: 300;
              // font-size: 1em;
              width: 100px;
            }
          }

          &__responses {
            display: flex;
            flex-direction: column;
            padding: 8px 16px;

            .response-header {
              font-size: 1em;
              font-weight: 450;
              margin: 10px 0 !important;
              // background-color: #e83e8c;
            }

            .question-explanation {
              >p {
                border: 1px solid rgba(128, 128, 128, 0.4);
                border-radius: 3px;
                hyphens: auto;
                margin: 10px 0;
                min-height: 3.5em;
                overflow-wrap: break-word;
                padding: 5px;
                width: 60%;
                word-wrap: break-word;
              }
            }

            .question-mitigation {
              &__container {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}