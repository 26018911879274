@import '../../../../../assets/styles/variables.scss';

.tab {
  margin: 0 0.5em 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 75px;
  // width: 290px;
  min-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $content-color-text;


  .link {
    background-color: $content-background-color;
    padding: 1em 0.75em;
    position: relative;
    text-decoration: none;
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // @include text-shadow(0 1px 0 rgba(255, 255, 255, 0.8));
    @include border-radius(5px 5px 0 0);
    //@include box-shadow($light-shadow);

    &:hover,
    &:focus,
    &.active {
      background: $background-color !important;
      font-weight: 700;
    }

    &:focus {
      outline: 0;
    }
  }

}

.title {
  z-index: 1031 !important;
}