

.authBackground {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(../../assets/images/background.jpg);
  background-position: left center;
  background-repeat: repeat-x;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  animation: animatedBackground 20s linear infinite;
}

@keyframes animatedBackground {
  from {
    background-position: left center;
  }

  to {
    background-position: right center;
  }
}

.authBackground:after {
  content: "";
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 80px;
  width: 250px;
  background-image: url(../../assets/images/logo-nuenergy.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 250px auto;
  background-color: transparent;
}

.loadingBackground {
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  display: flex;
  z-index: 700;
}