// @import '../../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/variables.scss';

.tabList {
  position: fixed;
  display: flex;
  flex-direction: row;
  margin: 0;
  z-index: 1030;
  overflow: hidden;
  // padding: 5px, 8px 0 8px;
  padding-top: 5px;
  padding-bottom: 0 !important;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 100vw;
  width: 100vw;
  width: fit-content;
  margin-top: 80px !important;
  background-color: $backdrop-color;
}