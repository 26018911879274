@import "../../../../assets/styles/variables.scss";



.container {
  // background-color: $content-background-color;

  // padding: 1rem 24px;

  // @include box-shadow($light-shadow);
  // @include border-radius($content-border-radius);

  padding-bottom: 25vh;

}


.tableContainer {
  background-color: $content-background-color;

  padding: 1rem 24px;

  //@include box-shadow($light-shadow);
  @include border-radius($content-border-radius);

  .table {

    // border: $content-border-color 1px solid;

    thead {
      border-bottom: $content-border-color 1px solid !important;
    }

    tbody {
      border-top: $content-border-color 3px solid !important;

    }
  }
}


.header {
  position: relative;
  display: flex;
  padding: 0.5rem 0 0 0.375rem;

  border-bottom: $content-border-color 1px solid;

  justify-content: space-between;

  align-items: center;

  .title {
    text-align: center;


  }


  .controller {


    .button {}

  }
}