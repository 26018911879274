@import './variables';
@import './extensions/index.scss';

// @import urkl('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import 'remixicon/fonts/remixicon.css';


html {
  scroll-behaviour: smooth;
}

body {
  --font-family-sans-serif: 'Inter', helvetica, arial, sans-serif;
  // --font-family-sans-serif: Roboto -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  // --font-family-monospace: montserrat, 'Open Sans', helvetica, arial, sans-serif;
  // --font-family-monospace: mono-regular, menlo, monaco, consolas, 'Liberation Mono', 'Courier New', monospace;
  --font-family: 'Inter', sans-serif;
  font-weight: 300;

  background-color: $background-color;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

html,
body {
  // margin: 0;
  // height: 100%;
  min-height: 100vh;
  // overflow: hidden
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0 !important;
  text-rendering: optimizeLegibility;
}

a {
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:active {
  outline: 0;
}


input {

  &[type=checkbox],
  &[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;

    border: 1px solid $content-color-text;
    transition: 0.2s all linear;
    margin-right: 5px;

    position: relative;

    &:disabled {
      border-color: $content-border-color;
    }

    &:checked {
      border: 6px solid $primary-color;
      outline: unset !important;
      /* I added this one for Edge (chromium) support */

      &:disabled {
        border-color: rgba($content-border-color, 0.75);
      }
    }
  }

  &[type=checkbox] {
    border-radius: 2px;
  }

  &[type=radio] {
    border-radius: 50% !important;
  }
}

input.form-control.invalid {
  border-color: #dc3545;
  color: #dc3545;
}

// For Edge-to-Edge Design
// <div className="row no-gutters"> ... </div>
.no-gutters {
  margin-right: 0;
  margin-left: 0;


  >.col,
  >[class*="col-"] {
    padding-right: 0;
    padding-left: 0;

  }
}

.dropdown-toggle.dropdown-toggle-split.btn {
  &.btn-secondary {
    border-left-color: #a9a9a9;
  }
}

.btn:disabled {
  cursor: not-allowed;
}

.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
}

.progress {
  border-radius: 50px;
}

.shadow-br {
  @include box-shadow($shadow-box);
  @include border-radius(5px);
}

.logo-nuenergy {
  height: 65px;
  // padding-right: 1rem;
}

.circle-icon,
.circle-icon-reverse,
.btn-floating-action {
  text-decoration: none;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle;
  padding: 0;

  .ri-share-fill {
    color: $primary-color-dark;
    font-size: 1.75rem;
    font-weight: 300;
  }
}

/* ------------------------- Colors ------------------------- */

.color-map-blue-text {
  color: $color-map-blue;
}

.color-map-indigo-text {
  color: $color-map-indigo;
}

.color-map-pink-text {
  color: $color-map-pink;
}

.color-map-red-text {
  color: $color-map-red;
}

.color-map-orange-text {
  color: $color-map-orange;
}

.color-map-green-text {
  color: $color-map-green;
}

.color-map-yellow-text {
  color: $color-map-yellow;
}

.color-map-purple-text {
  color: $color-map-purple;
}

.color-map-violet-text {
  color: $color-map-violet;
}

.color-map-teal-text {
  color: $color-map-teal;
}