@import "../variables.scss";

// The restyling of the survey created by SurveyJS
.sv_main {
  background-color: $content-background-color;

  .sv_custom_header {
    height: auto;
  }

  .sv_container {
    color: $content-color-text;

    .sv_row {
      .sv_qcbc {
        // Question 
        margin-left: 2rem;
      }

      .btn-group {
        margin-top: 16px;
      }

      //Questions
      h5 {
        font-size: 17px;
      }

      //question numbers
      span.sv_q_num {
        font-size: 20px;
        font-weight: 600;

      }

      //!uestion options
      span.sv-string-viewer {
        padding-left: 3px;
      }

      div.small {
        padding-left: 0px;
      }
    }

    .panel-heading.card-header {
      background-color: $content-background-color;

      .sv_header__text {
        h3 {
          font-size: 32px;

        }

        h3,
        h5 {
          font-weight: 300;
        }
      }
    }

    .panel-body.card-block {
      padding: 15px;

      h4 {
        font-size: 26px;
        font-weight: 300;
        margin-bottom: 25px;
      }

      span {
      }

      .progress.center-block {
        //@include box-shadow($light-shadow);
        // box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
        width: 80% !important;
        
        .progress-bar {
          background-color: $primary-color;
          border-radius: 0.25rem;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

      .btn-secondary:not(:disabled):not(.disabled).active {
        background-color: #20a8d8;
      }
    }
  }

  .card-footer {
    background-color: inherit !important;
    justify-content: flex-end;
    padding: 0.75rem 1.25rem;

    .sv-footer {
      &__right {
        justify-self: flex-end;
      }
    }
  }
}