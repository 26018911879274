@import "../../../assets/styles/variables.scss";

.container {
    display: flex;
    // max-width: 400px;
    text-align: left;
    position: relative;
    top:10px;
    left:40px;

    .toolsText{
        position: relative;
        // top:5px;
        .toolsHeader{
            font-size: 50px;
            color:$content-color-text;
        }
        .toolsParagraph{
            position: relative;
            top:10px;
            color:$content-color-text;
        }
    }

    .toolsContent{
        position: relative;
        // left: 400px;
        // bottom:155px;
        display: inline-flex;

        .toolsContentLeft{
            
            .toolsAif360{
                position: relative;
                height:300px;
                width:380px;
                border-radius: 8px;
                border-color: transparent;
                background-color: $nuenergy-blue;
                color: $background-color;
                font-size: 45px;
                text-align: left;
                padding-bottom: 60px;
                padding-left:15px;
            }

            .toolsAif360Paragraph{
                position: relative;
                top:10px;
                color:$primary-color-text;
                font-size: 16px;
                padding-bottom: 26px;
            }

            .toolsAif360Infobutton{
                background-color: lightblue;
                height: 25px;
                width: 75px;
                font-size: 10px;
                font-weight: 500;
                border-color: transparent;
                border-radius: 8px;
            }

        }

        .toolsContentRight{

            .toolsFairlearn{
                position: relative;
                height:300px;
                width:380px;
                left:20px;
                border-radius: 8px;
                border-color: transparent;
                background-color: $nuenergy-orange;
                color: $background-color;
                font-size: 45px;
                text-align: left;
                padding-bottom: 10px;
                padding-left:15px;
            }

            .toolsFairlearnParagraph{
                position: relative;
                top:10px;
                color:$primary-color-text;
                font-size: 16px;
            }

            .toolsFairlearnInfobutton{
                background-color: peachpuff;
                height: 25px;
                width: 75px;
                font-size: 10px;
                font-weight: 500;
                border-color: transparent;
                border-radius: 8px;
            }
        }

        }
    }

    




  