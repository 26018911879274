/*
 * File: tutorial.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:42:55 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:41:07 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../../variables';


.tutorial-info {
  h4 {
    font-size: 1.25rem;
    // font-weight: 350;
    // margin-bottom: 0.5rem;
  }

  .tut-content {
    margin-top: 0.5rem;
  }
}