@charset "utf-8";

@import "./bulma/sass/utilities/initial-variables.sass";

$primary: #4c8294;
$danger: #de656b;
$warning: #ffc000;
$success: #92d050;
$light-grey: #f2f2f2;

$background: $light-grey;
$scheme-main: $light-grey;
$scheme-main-bis: $light-grey;


