@import '../../../../assets/styles/variables.scss';

// The Main Drawer Component
.navDrawer {
  position: relative;
  max-width: 352px !important;
  width: fit-content;

  // @include box-shadow($light-shadow);
  background-color: $background-color;
  // color: #000;
  height: calc(100vh - 160px);
  margin-top: 160px;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  z-index: 1010;
  overflow: hidden !important;

  .multiLinkContainer {
    position: relative;
    // border-top: 1px solid $alto;
    padding: 1rem 0;
    width: 100%;
    height: 100%;
  }

  .content {
    position: fixed;
    max-width: $drawer-width;
    // background-color: $background-color;
    // background-color: $content-background-color;
    // @include box-shadow($light-shadow);
    height: 100%;
    padding: 0.5rem 1rem;
    // width: 100%;

    z-index: 1010;
  }
}