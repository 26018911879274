
@import "./bulma/sass/utilities/mixins.sass";
@import "510.scss";

@import "../../../../../../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "../../../../../../node_modules/bulma-tooltip/dist/css/bulma-tooltip.min.css";

.bitrapyRoot{


  .center{
    vertical-align: top; 
    margin-left: auto;
    margin-right: auto;
  }
    

  .subtitle {
    font-family: Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25; }
  
 .title{
  font-family: Helvetica;
  font-size: 2.3rem;
  font-weight: 600
  
 }
 .score_label{
  color: white;
  font-style: strong;
  position: absolute;
  width: 80%;
  top: 75px;
  
  font-size: 16px;
  left: 10%;
 }
 


.field_label {

  font-family: Helvetica;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;

  text-align: left;
  margin-right: 2rem;
  margin-left: 2rem;
  margin: 1.5 rm;
  @include tablet {
    flex-basis: 0;
    flex-grow: 1;
    font-size:1.5rem;
  }

}
.field_label_right {
  text-align:right;
  padding-right: 30px;
  @include tablet {
    flex-basis: 0;
    flex-grow: 1;
    font-size:1.5rem;
  }
}

.field {
  margin-bottom: 1rem;
  margin-right:1rem;
  display: flex;
  font-size: 1rem;
  
}

.level_item {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;

}
.hidden_level_item {
  align-items: center;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  display:none;

}
.level {
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
  &.is-mobile {
      .level-item {
        &:not(:last-child) {
          margin-bottom: 0;
          @include ltr-property("margin", 1rem);
        }
        &:not(.is-narrow) {
          flex-grow: 1;
        }
      }
    }
    @include tablet {
      display: flex;
      & > .level-item {
        &:not(.is-narrow) {
          flex-grow: 1;
        }
      }
    }
  
}

.center_align{
  text-align: center;
  padding-left: auto;
  padding-right: auto;
}

.section {
  padding: 3rem 1.5rem;
  // Responsiveness
  @include desktop {
    padding: 3rem 3rem;
    // Sizes
    &.is-medium {
      padding: 9rem 4.5rem;
    }
    &.is-large {
      padding: 18rem 6rem;
    }
  }
}









$control-radius: $radius !default;
$control-radius-small: $radius-small !default;

$control-border-width: 1px !default;

$control-height: 2.5em !default;
$control-line-height: 1.5 !default;

$control-padding-vertical: calc(0.5em - #{$control-border-width}) !default;
$control-padding-horizontal: calc(0.75em - #{$control-border-width}) !default;
$form-colors: $colors !default;

$input-color: $text-strong !default;
$input-background-color: $scheme-main !default;
$input-border-color: $border !default;
$input-height: $control-height !default;
$input-shadow: inset 0 0.0625em 0.125em rgba($scheme-invert, 0.05) !default;
$input-placeholder-color: bulmaRgba($input-color, 0.3) !default;

$input-hover-color: $text-strong !default;
$input-hover-border-color: $border-hover !default;

$input-focus-color: $text-strong !default;
$input-focus-border-color: $link !default;
$input-focus-box-shadow-size: 0 0 0 0.125em !default;
$input-focus-box-shadow-color: bulmaRgba($link, 0.25) !default;

$input-disabled-color: $text-light !default;
$input-disabled-background-color: $background !default;
$input-disabled-border-color: $background !default;
$input-disabled-placeholder-color: bulmaRgba($input-disabled-color, 0.3) !default;

$input-arrow: $link !default;

$input-icon-color: $border !default;
$input-icon-active-color: $text !default;

$input-radius: $radius !default;

.select {
  position: relative;
  &:not(.is-multiple):not(.is-loading) {
    &::after {
      border: 3px solid transparent;
      border-radius: 2px;
      border-right: 0;
      border-top: 0;
      content: " ";
      display: block;
      height: 0.625em;
      margin-top: -0.4375em;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: rotate(-45deg);
      transform-origin: center;
      width: 0.625em;
      border-color: $input-arrow;
      @include ltr-position(1.125em);
      z-index: 4;
    }
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    align-items: center;
    border: $control-border-width solid transparent;
    border-radius: $control-radius;
    box-shadow: none;
    display: inline-flex;
    font-size: $size-normal;
    height: $control-height;
    justify-content: flex-start;
    line-height: $control-line-height;
    padding-bottom: $control-padding-vertical;
    padding-left: $control-padding-horizontal;
    padding-right: $control-padding-horizontal;
    padding-top: $control-padding-vertical;
    position: relative;
    vertical-align: top;
    // States
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
      outline: none;
    }
    &[disabled],
    fieldset[disabled] & {
      cursor: not-allowed;
    }
    background-color: #f2f2f2;
    border-color: $input-border-color;
    border-radius: $input-radius;
    color: $input-color;
    @include placeholder {
      color: $input-placeholder-color;
    }
    cursor: pointer;
    display: block;
    font-size: 1em;
    max-width: 100%;
    outline: none;
    &::-ms-expand {
      display: none;
    }
    &[disabled]:hover,
    fieldset[disabled] &:hover {
      border-color: $input-disabled-border-color;
    }
    &:not([multiple]) {
      @include ltr-property("padding", 2.5em);
    }
    &[multiple] {
      height: auto;
      padding: 0;
      option {
        padding: 0.5em 1em;
      }
    }
  }
}


  
}




