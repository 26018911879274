@import '../../assets/styles/variables.scss';

.container {
    display: inline-block;
    max-width: 400px;
    text-align: left;
    position: relative;
    // bottom:30px;

    .text{
        .header{
            font-size: 50px;
            color:$content-color-text;
        }
        .content1{
            position: relative;
            top:30px;
            color:$content-color-text;
        }
        .content2{
            position: relative;
            top:30px;
            color:$content-color-text;
        }
        .content3{
            position: relative;
            top:12px;
            color:$nuenergy-blue;
        }
}
    
         
}
.MTPimg{
    display: inline-flex;
    position: relative; 
    // top:30px;    
        img {    
            max-width: 800px;
            max-height: 600px;
            position: relative;
            left:50px;
        }
}





  