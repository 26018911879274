@import "../../assets/styles/variables.scss";

.container {
  position: relative;

  .optionalHeader {
    display: flex;
    padding: 0.5rem 1.5rem !important;
  }

  table th,
  .optionalHeader, .footer {
    background-color: $backdrop-color;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    caption {
      font-size: 1.5em;
      margin: 0.5em 0 0.75em;
    }

    tr {
      background-color: #f8f8f8;
      padding: 0.35em;
    }

    th,
    td {
      padding: 0.625em;
    }

    th {
      font-size: 0.85em;
      letter-spacing: 0.1em;
    }
    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f2f2f2;
        }

        &:hover {
          background-color: #ddd;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    table {
      border: 0;
      caption {
        font-size: 1.3em;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      tr {
        // border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
      }
      td {
        // border-bottom: 1px solid #ddd;
        display: block;
        font-size: 0.8em;
        text-align: right;
      }
      td::before {
        /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        // text-transform: uppercase;
      }
      td:last-child {
        border-bottom: 0;
      }
    }
  }


  .footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1rem;

    span {
      margin: auto;
    }
    @include border-radius(0 0 $content-border-radius $content-border-radius);
  }
}
