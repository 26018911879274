@import '../../../../../../../assets/styles/variables.scss';


.container {
  position: relative;
  display: block;
  width: 100%;
  padding: 16px;

  .content {
    display: block;
  }

  .title {
    font-weight: 700;
    font-size: 18px;

  }

  .borderRight {
    border-right: $content-border-color 1px solid;
  }

  .group {

    border-bottom: $content-border-color 1px solid;


    .header {
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      padding: $content-padding;
      border-bottom: $content-border-color solid 1px;

      .controller {
        display: inline-flex;

        .btn {
          margin-left: 8px;
          // background-color: $background-color !important;
          font-size: smaller;
          width: 25px !important;
          height: 25px !important;
          padding: 0.15rem !important;
          align-content: center;
          align-items: center;
        }
      }
    }

  }

  .footer {
    display: flex;
    // align-self: end;
    justify-content: space-between;
    padding: 16px;

  }


}






.featureDetails {
  display: flex;
  // height: 100px;
  // background-color: aliceblue;

  align-content: center;
  justify-content: left;
  padding: 24px 0;

  @include border-radius($content-border-radius)
}



.selectContainer {
  display: flex;


  .select {
    height: 1.75rem;
    padding: 0.25rem;

    margin: auto 0.25rem;
  }
}