@import "../../../assets/styles/variables.scss";


.container {

  .controller {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }
}