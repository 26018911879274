.tool-wizard {


  .select-features {
    margin: 16px 0;

    .feature-label {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 8px !important;


      input {
        margin-right: 8px;

        &[type=radio] {
          
        }

        &[type=checkbox] {

        }

      }
    }




  }
}