/*
 * File: survey-container.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:47:29 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 25th August 2021 2:05:01 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

@import '../../variables';


/*
  Save, Draft and Clear button at the top of the survey
 */
.survey-control-btns {
  display: flex;
  justify-content: flex-start;
  // padding: 0 12vw 10px;
  margin-left: 1rem;
  margin-bottom: 1rem;
  width: 100%;


  .control-btn-container {
    display: flex;
    width: fit-content;
    position: relative;
    // background-color: #932b8e;

    .dropdown {
      @include border-radius($content-border-radius);
     //@include box-shadow($light-shadow);
    }

    &>button.btn,
    &>.dropdown {


      margin: 0 4px !important;
    }
  }

  .draft-drop-down-text {
    display: inline-flex;
    justify-content: space-between;
    min-width: 300px;
    padding: 3px 3px;

    span {
      padding-left: 5px;
    }
  }

  .dropdown.btn-group {
    display: flex;
    max-width: fit-content;
  }
}

/* The survey itself */
.survey-container,
.survey-container .survey-btn {
  // @include box-shadow($light-shadow)
}

.survey-container {
  background-color: $content-background-color;
  border-radius: 0.25em;
  // justify-self: center;
  // margin: 0 auto;
  margin-left: 1rem;
  max-width: 10in;
  padding: 15px;
  position: relative;
  top:20px;

  // Bottom of the survey
  .panel-footer.card-footer {
    background-color: inherit;
    display: flex;
    width: 100%;

    .right-survey-footer {
      padding: 4px;
    }


    .sv-footer {
      &__left {
        padding: auto;
      }

      &__right {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        button {
          margin: 0 16px;
        }
      }
    }
  }

  .survey-btn {
    @include border-radius($content-border-radius);

    background-color: $secondary-color;
    color: $secondary-color-text;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    margin-left: 10px;
    min-width: 100px !important;
    padding: 0.375rem 2em;
    text-align: center;
  }
}