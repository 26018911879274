/*
 * File: download-results.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 6:08:25 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:41:07 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../../variables';

.download-results {
  @include border-radius(50%);
 // @include box-shadow($shadow-box);

  background-color: $primary-color;
  border: 0;
  bottom: 25px;
  color: $primary-color-text;
  cursor: pointer;
  font-size: 24px;
  height: 60px;
  padding: 15px;
  position: fixed;
  right: 35px;
  text-align: center;

  /* Fixed/sticky position */
  width: 60px;
  z-index: 991;
}