@import "../../../assets/styles/variables.scss";


.container {



  .controller {
    display: flex;
    justify-content: center;
    margin: 0.5rem;
  }
}